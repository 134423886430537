@use "src/styles/variables";

.carousel {

  .carousel__circles {
    margin-top: 2rem;

    .carousel__circle {
      color: variables.$base-300;

      &.active {
        color: variables.$primary-900;
      }
    }
  }
}
