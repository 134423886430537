@use "src/styles/variables";

.footer__container {
  margin-top: auto;
  background-color: variables.$marketing-white;
  color: variables.$marketing-black;
  box-shadow: 0 0 0.3rem 0 variables.$base-500;
  padding: 0 1rem;

  @media only screen and (max-width: variables.$breakpoint-tablet) {
    padding: 0 0.8rem;
  }
}

.footer {
  max-width: variables.$width-layout;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
}

.footer__grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;

  @media only screen and (max-width: variables.$breakpoint-tablet) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    row-gap: 0.5rem;
    max-width: 30rem;
    margin: auto;
    padding: 1rem 0;
  }
}

.footer__grid-small {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  @media only screen and (max-width: variables.$breakpoint-tablet) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
}

.footer__meta {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding-bottom: 2rem;

  :nth-child(2) {
    text-align: center;
  }

  @media only screen and (max-width: variables.$breakpoint-tablet) {
    grid-template-columns: 1fr;
    justify-items: center;
    text-align: center;
  }
}

.footer__meta-links {
  margin: 0.5rem 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;

  @media only screen and (max-width: variables.$breakpoint-tablet) {
    justify-content: center;
  }
}

.footer__grid-tile {
  height: 50%;
  width: 50%;
  background-color: transparent;
  transition: variables.$transition;

  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;

  &.hover {
    width: 30%;
  }
}

.footer__grid-tile-body {
  opacity: 0;
  height: 0;
  transition: variables.$transition;
  background-color: transparent;

  &.hover {
    opacity: 1;
    height: 2.25rem;
  }
}
