@use "src/styles/variables";

.event-short {
  margin: 0;
  transition: variables.$transition;

  display: grid;
  grid-template-columns: 8rem auto;
  justify-content: start;
  justify-items: start;
  gap: 2rem;

  .event-short__head {
    width: 8.5rem;
    height: 8.5rem;
    padding: 0;
    margin: 0;
    border-radius: variables.$border-radius;
    background-color: variables.$marketing-red;
    color: variables.$marketing-white;
    text-align: center;
    transition: variables.$transition;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 0;

    .event-short__head-date {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      gap: 1rem;
    }
  }

  .event-short__body {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;
  }

  &:hover {
    transform: scale(1.05);

    .event-short__head {
      background-color: variables.$primary-500;
    }
  }

  &.EXTERNAL {
    .event-short__head {
      color: variables.$marketing-white;
      background-color: variables.$competec-blue;
    }

    &:hover {
      .event-short__head {
        background-color: variables.$competec-dark-blue !important;
      }
    }
  }

  @media only screen and (max-width: variables.$breakpoint-tablet) {
    grid-template-columns: auto;
    justify-content: center;
    justify-items: center;
  }
}
