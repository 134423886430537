.toasty {
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 1rem;
  overflow: hidden;
  background-color: transparent;

  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: flex-start;
  gap: 1rem;
}
