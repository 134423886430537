@use "src/styles/variables";

.profession-requirement {
  position: relative;
  margin: 1rem;
  aspect-ratio: 1;
  border: variables.$border-width variables.$border-style variables.$marketing-white;
  border-radius: variables.$border-radius;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  color: variables.$marketing-red;
  background-color: variables.$marketing-white;

  h5 {
    text-align: center;
  }

  &:hover {
    transition: variables.$transition;
    border: variables.$border-width variables.$border-style variables.$marketing-red;

    .profession-requirement__description {
      opacity: 1;
    }
  }

  .profession-requirement__description {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 1rem;
    opacity: 0;
    overflow: hidden;
    background-color: variables.$marketing-red;
    text-align: center;
    transition: variables.$transition;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    p {
      color: variables.$marketing-white;
      background-color: transparent;
    }
  }
}
