@use "src/styles/variables";

.pagination {

  .pagination__item, .pagination__icon {
    cursor: pointer;
  }

  .pagination__item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;

    &.active {
      background-color: variables.$base-700;
      color: variables.$base-50;
      border-radius: variables.$border-radius;
    }
  }

  .pagination__item, .break_label {
    padding: 0.5rem;
    font-size: 1.2rem;
    font-weight: bold;
    user-select: none;
  }
}
