@use "src/styles/variables";

.table {

  .table__header, .table__row {
    display: grid;
    grid-template-columns: repeat(var(--table-columns), 1fr);
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: start;
    gap: 0.5rem;
  }

  .table__header {
    margin-bottom: 1rem;
    padding: 0.25rem 1.5rem;
    border: 2px solid transparent;
  }

  .table__body {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    p {
      font-weight: bold;
    }

    .table__row {
      border-color: variables.$marketing-gray;
      border-style: variables.$border-style;
      border-width: variables.$border-width;
      border-radius: variables.$border-radius;
      padding: 0.25rem 1.5rem;
    }
  }
}
