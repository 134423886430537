@use "src/styles/variables";

.promotion-item {
  position: relative;
  border-radius: variables.$border-radius;
  overflow: hidden;

  .promotion-item__background {
    min-height: 30rem;

    .id-image {
      min-height: 30rem;
    }
  }

  .promotion-item__body {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 2rem;
    color: variables.$base-50;
    background: linear-gradient(0deg, rgba(variables.$base-900, 0.65) 0%, transparent 100%);

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 1rem;

    div {
      width: 100%;
      display: grid;
      grid-template-columns: auto 12rem;
      align-items: center;
      gap: 1rem;

      @media only screen and (max-width: variables.$breakpoint-tablet) {
        grid-template-columns: auto;

        h4 {
          font-size: 1rem;
          line-height: 1.6rem;
        }
      }
    }

    @media only screen and (max-width: variables.$breakpoint-tablet) {
      h1 {
        font-size: 2rem;
        line-height: 3rem;
      }
    }
  }
}
