@use "src/styles/variables";

.chip {
  padding: 0.4rem 0.8rem;
  cursor: pointer;
  background-color: variables.$marketing-gray;
  color: variables.$base-50;
  border-radius: variables.$border-radius;
  font-size: 1rem;
  font-weight: bold;

  @include variables.unselectable();
}
