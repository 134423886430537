.about-us-head {
  text-align: center;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  .image {
    max-width: 15rem;
    padding-bottom: 1rem;
  }
}
