@use "src/styles/variables";

.layout-article {
  position: relative;

  @media only screen and (max-width: variables.$breakpoint-tablet) {
    position: unset;
  }
}

.layout-article__image {
  position: absolute;
  z-index: 1;
  width: clamp(1rem, 50vw, 50rem);
  left: 50%;
  top: 15rem;
  background-color: transparent;

  @media only screen and (max-width: variables.$breakpoint-tablet) {
    width: 100%;
    position: unset;

    & > .id-image {
      border-radius: 0;
    }
  }
}

.layout-article__head {
  position: relative;
  height: 20rem;
  overflow: hidden;

  @media only screen and (max-width: variables.$breakpoint-tablet) {
    position: unset;
    height: unset;
    overflow: unset;
  }
}

.layout-article__head-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: variables.$width-content;
  margin: auto;
  padding: 6rem 1rem;
  z-index: 2;
  background-color: transparent;
  color: variables.$base-50;
  text-shadow: 0 0 2rem variables.$base-900;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;

  @media only screen and (max-width: variables.$breakpoint-tablet) {
    position: unset;
    padding: 3rem 1rem;
    color: unset;
    text-shadow: unset;
  }
}

.layout-article__head-image {
  position: absolute;
  z-index: 0;
  width: 100%;
  top: 0;
  left: 0;
  filter: blur(2rem);
  transform: scale(1.1);

  @media only screen and (max-width: variables.$breakpoint-tablet) {
    display: none;
  }
}

.layout-article__body {
  padding: 3rem 1rem;
  min-height: 20rem;

  @media only screen and (max-width: variables.$breakpoint-tablet) {
    min-height: unset;
  }
}

.layout-article__body-content {
  max-width: variables.$width-content;
  margin: auto;
}

.layout-article__body-content-half {
  width: 50%;
  padding-right: 3rem;

  @media only screen and (max-width: variables.$breakpoint-tablet) {
    width: unset;
    padding-right: unset;
  }
}
