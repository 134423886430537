@use "src/styles/variables";

.about-us-ruedi {
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  justify-items: center;
  gap: 2rem;

  .about-us-ruedi__head {
    max-width: 30rem;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    gap: 1rem;
  }

  .about-us-ruedi__body {
    padding: 2rem;
    border-left: 0.2rem variables.$border-style variables.$base-900;
  }

  @media only screen and (max-width: variables.$breakpoint-tablet) {
    grid-template-columns: 1fr;
  }
}
