@use "src/styles/variables";

.nav-item {
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  position: relative;

  &:hover {
    transition: variables.$transition;
    background-color: variables.$marketing-red;
  }

  &:hover a {
    transition: variables.$transition;
    color: variables.$marketing-white;
  }
}

.nav-item__link {
  color: variables.$marketing-black;
  padding: 1rem;
  border-radius: variables.$border-radius;
  -webkit-tap-highlight-color: transparent;
  font-size: 1.2rem;
  font-weight: bold;

  @include variables.unselectable();

  &.active {
    color: variables.$marketing-red;
  }
}

.nav-item__dropdown {
  background-color: variables.$marketing-light-gray;
  margin: 0;
  padding: 1rem;
  list-style-type: none;
  white-space: nowrap;
  position: absolute;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  top: 100%;
  left: 0;

  li {
    padding: 0.3rem 0;
  }
}

.nav-item__dropdown-link {
  font-size: 1.2rem;
  font-weight: normal;
  color: variables.$marketing-black !important;

  &:hover {
    text-decoration: underline;
  }
}
