@use "src/styles/variables";

.promotion {
  width: 90%;
  height: 90%;
  align-self: center;
}

.center {
  text-align: center;
}
