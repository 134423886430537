@use "src/styles/variables";

.blog-short {
  margin-bottom: 2rem;
  transition: variables.$transition;

  display: flex;
  flex-direction: column;

  &:hover {
    scale: 1.05;
  }
}

.blog-short__head {
  position: relative;
}

.blog-short__head-chips {
  position: absolute;
  width: 100%;
  padding: 1rem;
  overflow: hidden;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}

.blog-short__body {
  padding: 1rem;

  > p {
    margin-top: 1rem;

    @include variables.ellipsis;

    @supports (-webkit-line-clamp: 3) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }

}
