@use "src/styles/variables";

.icon {
  color: inherit;
  background-color: transparent;
  font-size: inherit;
  transition: variables.$transition;

  @include variables.unselectable;

  &.XS {
    font-size: 1rem;
  }

  &.S {
    font-size: 1.5rem;
  }

  &.M {
    font-size: 2rem;
  }

  &.L {
    font-size: 2.5rem;
  }

  &.XL {
    font-size: 3rem;
  }

  &.XXL {
    font-size: 4rem;
  }

  font-family: 'Material Symbols Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}
