@use "src/styles/variables";

.footer-tile {
  aspect-ratio: 2;
  grid-row: auto / span 1;
  padding: 15%;
  margin: 5%;
  overflow: hidden;
  border-radius: variables.$border-radius;
  transition: variables.$transition;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;

  &:hover {
    z-index: 1;
  }
}
