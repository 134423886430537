@use "src/styles/variables";

.id-image {
  width: 100%;
  border-radius: variables.$border-radius;
  aspect-ratio: 16 / 9;
  object-fit: cover;
}

.id-image__placeholder {
  background-color: variables.$base-400;
  width: 100%;
  border-radius: variables.$border-radius;
  aspect-ratio: 16 / 9;
  overflow: hidden;
  animation: id-image__placeholder linear 5000ms infinite;
  animation-delay: var(--id-image__placeholder-delay);
}

@keyframes id-image__placeholder {
  0%, 100% {
    background-color: variables.$base-400;
  }

  50% {
    background-color: variables.$base-200;
  }
}
