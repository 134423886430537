@use "src/styles/variables";

.marktplatz {
  padding: 1rem 0;
  position: relative;
  background-color: variables.$marketing-white;

  .marktplatz-project {
    position: absolute;
    width: 30%;
    bottom: 10%;
    right: 10%;

    animation: marktplatz-project 8000ms infinite;
  }
}

@keyframes marktplatz-project {
  0% {
    bottom: 10%;
    right: 10%;
  }
  20% {
    bottom: 40%;
    right: 20%;
  }
  50% {
    bottom: 5%;
    right: 50%;
  }
  80% {
    bottom: 30%;
    right: 5%;
  }
  100% {
    bottom: 10%;
    right: 10%;
  }
}
