@use "src/styles/variables";

.about-us-way {
  max-width: 40rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 3rem;

  .about-us-way__container {
    padding: 2rem;
    border-radius: variables.$border-radius;
    background-color: variables.$marketing-gray;
    color: variables.$base-50;
  }
}
