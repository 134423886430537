@use "src/styles/variables";

.about-us-competec {
  margin: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 3rem;

  .about-us-competec__sector {
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-content: center;
    align-items: center;
    justify-content: center;
    gap: 2rem;

    @media only screen and (max-width: variables.$breakpoint-mobile) {
      grid-template-columns: 1fr;
    }
  }
}
