@use "variables";

*, :before, :after {
  font-family: variables.$font-family;
  color: inherit;
  background-color: inherit;
  word-wrap: break-word;
}

h1 {
  font-size: 3rem;
  line-height: 4rem;
  font-weight: bold;
  margin: 0;
  color: inherit;
  width: 100%;
  background-color: transparent;

  @media only screen and (max-width: variables.$breakpoint-mobile) {
    font-size: 2.5rem;
    line-height: 3.5rem;
  }
}

h2 {
  font-size: 2rem;
  line-height: 3rem;
  font-weight: bold;
  margin: 0;
  color: inherit;
  width: 100%;
  background-color: transparent;

  @media only screen and (max-width: variables.$breakpoint-mobile) {
    font-size: 1.8rem;
    line-height: 2.8rem;
  }
}

h3 {
  font-size: 1.5rem;
  line-height: 2.5rem;
  font-weight: normal;
  margin: 0;
  color: inherit;
  background-color: transparent;

  @media only screen and (max-width: variables.$breakpoint-mobile) {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
}

h4 {
  font-size: 1.25rem;
  line-height: 2.25rem;
  font-weight: bold;
  margin: 0;
  color: inherit;
  background-color: transparent;
}

h5 {
  font-size: 1.25rem;
  line-height: 2.25rem;
  font-weight: normal;
  margin: 0;
  color: inherit;
  background-color: transparent;
}

h6 {
  font-size: 1rem;
  line-height: 2rem;
  font-weight: bold;
  margin: 0;
  color: inherit;
  background-color: transparent;
}

p {
  max-width: 60rem;
  font-size: 1rem;
  line-height: 2rem;
  font-weight: normal;
  margin: 0;
  color: inherit;
  background-color: transparent;
}

small {
  max-width: 60rem;
  font-size: 0.75rem;
  line-height: 1.75rem;
  font-weight: normal;
  margin: 0;
  color: inherit;
  background-color: transparent;
}

strong {
  color: variables.$marketing-red;
}

a {
  text-decoration: none;
  font-weight: bold;
}

blockquote {
  font-size: 1rem;
  line-height: 2rem;
  font-weight: bold;
  font-style: italic;
  margin: 1rem;
  padding: 2rem;
  border-left-color: inherit;
  border-left-width: 0.2rem;
  border-left-style: variables.$border-style;
  color: inherit;
  background-color: transparent;
}

pre {
  font-size: 1rem;
  line-height: 2rem;
  font-weight: normal;
  margin: 0;
  color: inherit;
  @include variables.ellipsis;
  background-color: transparent;
}

br {
  line-height: 1rem;
  background-color: transparent;
}

hr {
  height: 0;
  border-width: 0;
  border-color: inherit;
  border-top-width: variables.$border-width;
  border-top-style: variables.$border-style;
  margin: 0 0 1rem 0;
  line-height: 0;
  opacity: 0.5;
  max-width: 6rem;
  background-color: transparent;
}

ul, ol {
  margin: 0;
  padding: 0 0 0 2rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
}

li {
  font-size: 1rem;
  line-height: 2rem;
  font-weight: normal;
  margin: 0;
  color: inherit;
  background-color: transparent;
}
