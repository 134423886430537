@use "src/styles/variables";

.image {
  width: 100%;
  border-radius: variables.$border-radius;

  &.clickable {
    cursor: pointer;
  }
}
