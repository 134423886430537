@use "src/styles/variables";

.vision-and-mission {
  display: flex;
  justify-content: center;
  align-items: center;

  .vision-and-mission__icons {
    padding: 2rem 0;

    .icon img {
      height: 6rem;
      width: auto;
      cursor: pointer;
    }

    .gap {
      padding: 1.5rem;
    }

    @media only screen and (max-width: variables.$breakpoint-mobile) {
      padding: 1rem 0;

      .icon img {
        height: 4.5rem;
        width: auto;
        cursor: pointer;
      }

      .gap {
        padding: 1.3rem;
      }
    }
  }
}

.graphic-container {
  padding: 1rem 0;
  margin: 0 auto;
  width: 75%;
  cursor: pointer;

  @media only screen and (max-width: variables.$breakpoint-tablet) {
    width: 100%;
  }
}

