@use "src/styles/variables";

.dropdown {
  position: relative;
  color: variables.$base-900;
}

.dropdown__container {
  position: absolute;
  width: 100%;
  display: none;
  background-color: transparent;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
  z-index: 2;

  &.visible {
    display: flex;
  }
}

.dropdown__list {
  width: 100%;
  background-color: variables.$base-50;
  border-left: variables.$border-width variables.$border-style variables.$base-100;
  border-right: variables.$border-width variables.$border-style variables.$base-100;
  border-bottom: variables.$border-width variables.$border-style variables.$base-100;
  border-top: none;
  border-radius: 0 0 variables.$border-radius variables.$border-radius;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: flex-start;
  justify-content: center;
}
