@use "src/styles/variables";

.layout-summary {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 1rem;

  @media only screen and (max-width: variables.$breakpoint-tablet) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.layout-summary__navigation {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
}

.layout-summary__navigation-element {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;

  & > .button {
    text-align: left;
  }
}

.layout-summary__navigation-element-indicator {
  width: 0.2rem;
  height: 2rem;

  &.current {
    background-color: white;
  }

  @mixin layoutSummaryStyle($font-color) {
    &.current {
      background-color: $font-color;
    }
  }

  &.BASE {
    @include layoutSummaryStyle(variables.$marketing-red);
  }

  &.BASE_INVERTED {
    @include layoutSummaryStyle(variables.$marketing-red);
  }

  &.PRIMARY {
    @include layoutSummaryStyle(variables.$base-50);
  }

  &.PRIMARY_INVERTED {
    @include layoutSummaryStyle(variables.$base-50);
  }

  &.SECONDARY {
    @include layoutSummaryStyle(variables.$base-50);
  }

  &.SECONDARY_INVERTED {
    @include layoutSummaryStyle(variables.$base-900);
  }
}

.layout-summary__content {
  text-align: center;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  gap: 1rem;

  & > h1 {
    font-size: 9rem;
    line-height: 10rem;

    @media only screen and (max-width: variables.$breakpoint-tablet) {
      font-size: 6rem;
      line-height: 7rem;
    }

    @media only screen and (max-width: variables.$breakpoint-mobile) {
      font-size: 3rem;
      line-height: 4rem;
    }
  }
}

.circles {
  margin: auto;
  display: flex;

  .circle {
    padding: 0.5rem;
    color: variables.$base-300;

    &.current {
      color: variables.$primary-900;
    }
  }
}
