@use "variables";

html, body, #root {
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  scroll-behavior: auto;
}

#root {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
}

body {
  color: variables.$base-900;
  background-color: variables.$base-50;
}

iframe {
  border: none;
  border-radius: variables.$border-radius;
}

::-webkit-scrollbar {
  width: 0.4rem;
  height: 0.4rem;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: variables.$marketing-gray;
  background-clip: content-box;
}

::selection {
  color: variables.$base-50;
  background-color: variables.$primary-300;
}
