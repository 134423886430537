@use "src/styles/variables";

.apply-container {
  display: flex;
  align-items: center;

  img {
    padding-right: 1rem;
    height: 18rem;
    width: auto;
  }
}

.vacancies-container {
  display: flex;
  align-items: center;

  img {
    padding-left: 2rem;
    height: 15rem;
    width: auto;
    cursor: pointer;
  }
}

@media only screen and (max-width: variables.$breakpoint-tablet) {
  .apply-container {
    flex-direction: column;
    align-items: center;

    img {
      padding: 0 0 2rem 0;
      height: 15rem;
      width: auto;
    }
  }

  .vacancies-container {
    flex-direction: column;
    align-items: flex-start;

    img {
      padding: 0;
    }
  }
}
