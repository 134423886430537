@use "src/styles/variables";

.layout-section {
  width: 100%;
  padding: 4rem 1rem;
  color: inherit;

  @mixin layoutSectionStyle($font-color, $container-color) {
    background-color: $container-color;
    color: $font-color;
    border-color: $font-color;
  }

  &.BASE {
    @include layoutSectionStyle(variables.$marketing-black, variables.$marketing-light-gray);
  }

  &.BASE_INVERTED {
    @include layoutSectionStyle(variables.$marketing-black, variables.$marketing-white);
  }

  &.PRIMARY {
    @include layoutSectionStyle(variables.$marketing-white, variables.$marketing-red);
  }

  &.PRIMARY_INVERTED {
    @include layoutSectionStyle(variables.$base-900, variables.$primary-200);
  }

  &.SECONDARY {
    @include layoutSectionStyle(variables.$base-50, variables.$secondary-700);
  }

  &.SECONDARY_INVERTED {
    @include layoutSectionStyle(variables.$base-900, variables.$secondary-200);
  }
}

.layout-section__content {
  max-width: variables.$width-content;
  margin: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 2rem;
}
