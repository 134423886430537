.requirements {
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    padding: 0 0 0.5rem 0;
  }

  li h6 {
    padding: 0 0 0.5rem 0;
  }
}
