@use "src/styles/variables";

.layout-sequence {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;

  &.ROW {
    flex-direction: row;
  }

  &.COLUMN {
    flex-direction: column;
  }

  &.ROW_CENTER {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &.COLUMN_CENTER {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
