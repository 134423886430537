@use "src/styles/variables";

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner__container {
  display: flex;
  justify-content: center;

  .spinner {
    display: inline-block;
    width: 3.125rem;
    height: 3.125rem;
    border-width: 0.313rem;
    border-style: variables.$border-style;
    border-color: inherit;
    border-top-color: transparent;
    border-radius: 50%;
    animation: spinner 1.25s cubic-bezier(0.27, 0.63, 1, 1) infinite;
  }
}
