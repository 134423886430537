@use "src/styles/variables";

.testimonial-short {
  transition: variables.$transition;

  &:hover {
    scale: 1.05;
  }

  .testimonial-short__body {
    padding: 1rem;

    > p {
      margin-bottom: 1rem;

      @include variables.ellipsis;

      @supports (-webkit-line-clamp: 3) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }
}
