@use "src/styles/variables";
@use "src/styles/typography";

.button {
  border-radius: variables.$border-radius;
  border-width: variables.$border-width;
  border-style: variables.$border-style;
  transition: variables.$transition;
  cursor: pointer;

  font-size: 1rem;
  min-width: 6rem;
  padding: 0.8rem 1.6rem;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.313rem;

  color: variables.$base-900;
  background-color: transparent;
  border-color: transparent;

  & > * {
    background-color: transparent;
  }

  @mixin buttonStyle($font-color, $button-color, $button-hover-color) {
    &.FILLED {
      color: $font-color;
      background-color: $button-color;
      border-color: $button-color;

      &:hover {
        background-color: $button-hover-color;
        border-color: $button-hover-color;
      }
    }

    &.OUTLINED {
      color: inherit;
      background-color: transparent;
      border-color: $button-color;

      &:hover {
        border-color: $button-hover-color;
        background-color: rgba($button-hover-color, 0.2);
      }
    }

    &.TEXT {
      color: inherit;
      background-color: transparent;
      border-color: transparent;

      &:hover {
        background-color: rgba($button-hover-color, 0.2);
      }
    }
  }

  &.BASE {
    @include buttonStyle(variables.$marketing-white, variables.$marketing-gray, variables.$marketing-gray);
  }

  &.BASE_INVERTED {
    @include buttonStyle(variables.$marketing-gray, variables.$base-100, variables.$base-50);
  }

  &.PRIMARY {
    @include buttonStyle(variables.$base-50, variables.$marketing-red, variables.$marketing-red);
  }

  &.PRIMARY_INVERTED {
    @include buttonStyle(variables.$base-50, variables.$primary-300, variables.$primary-200);
  }

  &.SECONDARY {
    @include buttonStyle(variables.$base-50, variables.$secondary-600, variables.$secondary-700);
  }

  &.SECONDARY_INVERTED {
    @include buttonStyle(variables.$base-900, variables.$secondary-300, variables.$secondary-200);
  }
}
