@use "src/styles/variables";

.icon-button {
  transition: variables.$transition;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;

  @mixin iconStyle($dimension) {
    width: $dimension;
    height: $dimension;
  }

  &.XS {
    @include iconStyle(2rem);
  }

  &.S {
    @include iconStyle(2.5rem);
  }

  &.M {
    @include iconStyle(3rem);
  }

  &.L {
    @include iconStyle(3.5rem);
  }

  &.XL {
    @include iconStyle(4rem);
  }

  &:hover {
    background-color: variables.$base-100;
  }

  @mixin iconButtonStyle($font-color, $button-color, $button-hover-color) {
    background-color: $button-color;
    color: $font-color;

    &:hover {
      background-color: $button-hover-color;
    }
  }

  &.BASE {
    @include iconButtonStyle(variables.$base-50, variables.$base-800, variables.$base-900);
  }

  &.BASE_INVERTED {
    @include iconButtonStyle(variables.$marketing-black, variables.$marketing-white, variables.$marketing-light-gray);
  }

  &.PRIMARY {
    @include iconButtonStyle(variables.$base-50, variables.$primary-600, variables.$primary-700);
  }

  &.PRIMARY_INVERTED {
    @include iconButtonStyle(variables.$base-50, variables.$primary-300, variables.$primary-200);
  }

  &.SECONDARY {
    @include iconButtonStyle(variables.$base-50, variables.$secondary-600, variables.$secondary-700);
  }

  &.SECONDARY_INVERTED {
    @include iconButtonStyle(variables.$base-900, variables.$secondary-300, variables.$secondary-200);
  }
}
