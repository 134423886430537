@use "src/styles/variables";

.testimonial-short-preview {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  .testimonial-short__head {
    padding: 0;
  }

  .testimonial-short__body, .testimonial-short__head {
    flex: 1 0 50%;
  }

  .testimonial-short__body {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: nowrap;
    border-left: 0.2rem variables.$base-900 variables.$border-style;
    padding: 2rem 5rem 2rem 2rem;

    > p {
      margin-bottom: 1rem;

      @include variables.ellipsis;

      @supports (-webkit-line-clamp: 3) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }

    h4 {
      line-height: 1rem;
    }

  }
}

@media only screen and (max-width: variables.$breakpoint-tablet) {
  .testimonial-short-preview {
    flex-direction: column;

    .testimonial-short__head {
      padding: 0;
    }

    .testimonial-short__body {
      margin: 2rem 1rem;
      padding: 1.5rem;
    }
  }
}

@media only screen and (max-width: variables.$breakpoint-mobile) {
  .testimonial-short-preview {

    .testimonial-short__body {
      padding: 1rem;
      margin: 1rem 0;
    }
  }
}
