@use "src/styles/variables";

.layout-grid {
  display: grid;
  gap: 2rem;
  align-content: center;
  align-items: start;
  justify-items: stretch;

  grid-template-columns: repeat(var(--layout-grid-columns-desktop), 1fr);

  @media only screen and (max-width: variables.$breakpoint-tablet) {
    grid-template-columns: repeat(var(--layout-grid-columns-tablet), 1fr);
  }

  @media only screen and (max-width: variables.$breakpoint-mobile) {
    grid-template-columns: repeat(var(--layout-grid-columns-mobile), 1fr);
  }
}
