@use "src/styles/variables";

.main {
  overflow: hidden;
  min-height: calc(100vh - 5rem);

  @media only screen and (max-width: variables.$breakpoint-tablet) {
    min-height: calc(100vh - 3.5rem);
  }
}
