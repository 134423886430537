@use "src/styles/variables";

.not-found__image {
  width: 80%;
  margin: auto;

  @media only screen and (max-width: variables.$breakpoint-tablet) {
    width: 100%;
  }
}
