@use "src/styles/variables";

.profession-model {
  position: relative;
  height: 50rem;
  overflow: hidden;
  border-radius: variables.$border-radius;

  &:hover {
    .profession-model__image {
      filter: blur(0);
    }
  }

  .profession-model__image {
    position: absolute;
    height: 100%;
    filter: blur(1rem);
    transition: variables.$transition;

    .id-image {
      height: 100%;
      object-fit: cover;
    }
  }

  .profession-model__body {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 2rem;
    color: variables.$base-50;
    background-color: rgba(variables.$base-900, 0.25);

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    gap: 2rem;

    & > * {
      background-color: transparent;
    }
  }
}
