@use "src/styles/variables";

.toasty-item {
  position: relative;
  padding: 1rem;
  overflow: hidden;
  border-radius: variables.$border-radius;
  box-shadow: 0.5rem 0.5rem 0.5rem 0 rgba(variables.$marketing-gray, 0.25);

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  animation: toast-in-right 500ms;


  @mixin toastStyle($toast-background, $after-background) {
    background-color: $toast-background;

    > .icon-button {
      background-color: transparent;
    }

    &::after {
      background-color: $after-background;
    }
  }

  &.ERROR {
    @include toastStyle(variables.$primary-50, variables.$primary-100);
  }

  &.INFO {
    @include toastStyle(variables.$base-200, variables.$base-400);
  }

  &.SUCCESS {
    @include toastStyle(variables.$secondary-50, variables.$secondary-100);
  }

  &:after {
    content: '';
    position: absolute;
    height: 100%;
    width: calc(100% + 5rem);
    left: -2.5rem;
    animation: toast-expire linear 3000ms;
    z-index: 1;
    transform: skew(-30deg, 0deg);
  }

  > * {
    z-index: 2;
    background-color: transparent;
  }
}

@keyframes toast-expire {
  from {
    width: 0;
  }
  to {
    width: calc(100% + 5rem);
  }
}

@keyframes toast-in-right {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
