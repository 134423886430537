@use "src/styles/variables";

.profession-summary {
  max-width: 50rem;
  margin-left: auto;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 2rem;

  .profession-summary__head {
    padding: 0 2rem;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }

  .profession-summary__body {
    padding: 0 2rem;
  }

  @media only screen and (max-width: variables.$breakpoint-tablet) {
    .profession-summary__head {
      flex-direction: column;
    }
  }

  @media only screen and (max-width: variables.$breakpoint-mobile) {
    .profession-summary__head {
      padding: 0;
    }

    .profession-summary__body {
      padding: 0;
    }
  }
}
