@use "src/styles/variables";

.contact {

  .maps__iframe {
    padding: 1rem 0 0 0;
    margin: 0;
    width: 100%;
    height: 45vh;
  }
}
